import React, { RefObject } from 'react'
import PowerBI, { models, Report } from "./PowerBI";
import Viewer, { CustomViewer } from "./Viewer";

let viewerRef: RefObject<any>;
let reportRef: RefObject<any>;

type target = { column: string, table: string }
type value = { equals: string, target: target }

const syncModels = () => {

    const report = reportRef.current.state.report as Report;
    const viewer = viewerRef.current.state.viewer as CustomViewer;

    report.getFilters()
        .then(filters => {
            const fileFilter = filters.filter(({ target }) => (target as models.IColumnTarget).column === 'IFCFILE')[0] as models.ITupleFilter
            viewer.setModels(reportRef.current.props.dataset, fileFilter.values as unknown as string[]);
        })
}

const syncData = () => {

    const report = reportRef.current.state.report as Report;
    const viewer = viewerRef.current.state.viewer as CustomViewer;

    report.getPages()
        .then(pages => pages[0].getVisuals()
            .then(visuals => {
                const globalIdTable = visuals.filter(visual => visual.title === 'GLOBALID')[0];
                globalIdTable.exportData()
                    .then(result => {
                        const globalIds = result.data.split('\r\n').slice(1, -1).map(s => s.split('#')[0]);
                        viewer.setObjects(globalIds);
                    });
            }))
}

const syncSelected = (dataPoints: { identity: value[] }[]) => {

    const viewer = viewerRef.current.state.viewer as CustomViewer;

    if (dataPoints.length === 0) {
        viewer.refresh();
    } else {
        const identity: value[] = dataPoints[0].identity;
        const globalIdCols = identity.filter(value => value.target.column === 'GLOBALID');
        if (globalIdCols.length > 0) viewer.selectObjects([globalIdCols[0].equals]);
    };
}

const onLoad = () => {

    const report = reportRef.current.state.report as Report;

    report.on('dataSelected', (event: CustomEvent) => {
        syncSelected(event.detail.dataPoints);
    });

    report.on('rendered', (event: CustomEvent) => {
        syncModels();
        syncData();
    });
}

interface Props { dataset: string };
interface State { };

class AnalyticsTab extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        viewerRef = React.createRef();
        reportRef = React.createRef();
        //this.state = { reports: [], activeReport: '' }
        //this.setState = this.setState.bind(this);
    }

    render(): JSX.Element {
        return <div>
            <div className="d-flex flex-row ">
                <div className="w-100">
                    <Viewer ref={viewerRef} filterOption={true} />
                </div>
                <PowerBI ref={reportRef} dataset={this.props.dataset} onLoad={onLoad} />
            </div>
        </div>
    }
}

export default AnalyticsTab;