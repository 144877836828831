import React from 'react';
import { Storage } from '@aws-amplify/storage';
import { CloudDownloadFill, XCircleFill, CloudUploadFill } from 'react-bootstrap-icons';
import { FileUploader } from "react-drag-drop-files";
import "./ExcelTab.css";


const ExcelFile = (props: { filePath: string, fileList: string[], setState: any }): JSX.Element => {

    const file = props.filePath.split('/').at(-1) ?? ''
    const [signedURL, setSignedURL]: [string, any] = React.useState('');

    React.useEffect(() => {
        Storage.get(props.filePath).then(url => setSignedURL(url))
    }, [props.filePath])

    const remove = () => {
        Storage.remove(props.filePath);
        props.setState({ fileList: props.fileList.filter(item => item !== props.filePath) });
    }

    const btnStyle = "btn mx-1 btn-sm rounded"
    return <div className="d-flex align-items-center justify-content-between">
        {file}
        <div>
            <button className={btnStyle}><XCircleFill size={24} onClick={remove} /></button>
            <a className={btnStyle} href={signedURL}><CloudDownloadFill size={24} /></a>
        </div>
    </div>
}

const ExcelFileList = (props: { fileList: string[], setState: any, dataset?: string }): JSX.Element => {

    React.useEffect(() => {
        const fetchFiles = async () => {
            Storage.list(`${props.dataset}/excel/`)
                .then(result => {
                    const excelFiles = result.map(obj => (obj.key) ? obj.key : '');
                    if (excelFiles) props.setState({ fileList: excelFiles });
                })
                .catch(err => console.log(err));
        };
        if (props.dataset) fetchFiles();
    }, [props]);

    return <div className="mt-4">
        <h3>Files</h3>
        <div className="mt-4 d-flex justify-content-center">
            <ul className="w-50 list-group">
                {props.fileList.map(filePath => <li className="list-group-item" key={filePath}>
                    <ExcelFile fileList={props.fileList} setState={props.setState} filePath={filePath} />
                </li>
                )}
            </ul>
        </div>
    </div>
}

interface Props { dataset: string };
interface State { fileList: string[] };

class ExcelTab extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = { fileList: [] }
        this.setState = this.setState.bind(this);
    }

    DragDrop = (): JSX.Element => {

        const DragDropInside = (): JSX.Element => {
            return <div className="align-self-center">
                <div className="btn mx-1 btn-sm rounded"><CloudUploadFill size={24} /></div>Click or drop Excel file
            </div>
        }
        return <div className="mt-4 d-flex justify-content-center">
            <FileUploader classes="dropArea border border-primary rounded d-flex justify-content-center "
                handleChange={this.upload} children={<DragDropInside />} />
        </div>
    }

    upload = (file: File) => {
        Storage.put([this.props.dataset, 'excel', file.name].join('/'), file, {
            level: 'public',
            contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }).then(response => this.setState({ fileList: this.state.fileList.concat([response.key]) })
        )
    }

    render(): JSX.Element {
        return <div>
            <h2>Upload Excel file</h2>
            <this.DragDrop />
            <ExcelFileList fileList={this.state.fileList} setState={this.setState} dataset={this.props.dataset} />
        </div>
    }
}

export default ExcelTab;