import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from '@aws-amplify/core';
import { Navigation, ViewerTab, AnalyticsTab } from './components';
import '@aws-amplify/ui-react/styles.css';
import config from './aws-exports';
Amplify.configure(config);

const Home = () => {
  return <h1>Welcome</h1>;
};


interface Props { };
interface State { datasets: string[], activeDataset: string };

class App extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = { datasets: [], activeDataset: '' }
    this.setState = this.setState.bind(this);
  }

  render(): React.ReactNode {
    return <Authenticator variation="modal" hideSignUp={true}>
      {({ signOut, user }) => {

        if (!user) return <div>Login failed</div>

        const customSignOut = async () => {
          (signOut as any)();
          user.signOut();
          this.setState({ datasets: [], activeDataset: '' })
        }

        return <div className="container-fluid py-3">
          <BrowserRouter>
            <Navigation
              signOut={customSignOut}
              user={user}
              appState={this.state}
              setAppState={this.setState}
            />
            <main>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/viewer" element={<ViewerTab dataset={this.state.activeDataset} />} />
                <Route path="/analytics" element={<AnalyticsTab dataset={this.state.activeDataset}/>} />
                {/* <Route path="/excel" element={<ExcelTab dataset={this.state.activeDataset} />} /> */}
              </Routes>
            </main>
          </BrowserRouter>
        </div>
      }}
    </Authenticator>
  }
}

export default App