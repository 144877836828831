import React, { RefObject } from "react";
import { UserAgentApplication, AuthError, AuthResponse } from "msal";
import { service, factories, models, IEmbedConfiguration, Report } from "powerbi-client";
import "./PowerBI.css";

const PowerBIService = new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory);

// Refer https://aka.ms/PowerBIPermissions for complete list of Power BI scopes
const scopes: string[] = ["https://analysis.windows.net/powerbi/api/Report.Read.All"];

// Client Id (Application Id) of the AAD app.
const clientId: string = "ba137db2-1fbf-4f0e-9907-f9f95546a129";

let accessToken = "";
let reportContainer: HTMLElement;
let reportRef: RefObject<any>;
let report: Report;

const settings: models.ISettings = {
    layoutType: models.LayoutType.Custom,
    customLayout: {
        displayOption: models.DisplayOption.ActualSize
    },
    panes: {
        bookmarks: {
            visible: false
        },
        fields: {
            expanded: false,
            visible: false
        },
        filters: {
            expanded: true,
            visible: true
        },
        pageNavigation: {
            visible: false
        },
        selection: {
            visible: false
        },
        syncSlicers: {
            visible: false
        },
        visualizations: {
            expanded: false,
            visible: false
        }
    },
    bars: {
        actionBar: {
            visible: false
        }
    },
    background: models.BackgroundType.Default,
    localeSettings: {
        formatLocale: "de-DE",
        language: "de"
    }
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
    dataset: string,
    onLoad?: () => void
};
interface State {
    error: string[],
    reports: {
        [report: string]: IEmbedConfiguration
    },
    report?: Report
};

class PowerBI extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        reportRef = React.createRef();
        this.state = {
            error: [],
            reports: {},
            report: undefined
        };
    }

    workspaces: { [key: string]: string } = {
        demo: "8f236587-b673-4fff-8e65-9242f6df3d29",
        edd: "936f33dc-7061-4654-92de-2cbce5c51d7c"
    }

    embedReport = (config: IEmbedConfiguration) => {
        report = PowerBIService.embed(reportContainer, config) as Report;
        this.setState({ report: report });
    }

    getReports = () => {
        const workspaceId = this.workspaces[this.props.dataset];
        if (!workspaceId) return;
        fetch("https://api.powerbi.com/v1.0/myorg/groups/" + workspaceId + "/reports", {
            headers: {
                "Authorization": "Bearer " + accessToken
            },
            method: "GET"
        }).then(response => response.json().then(
            ({ value }) => {
                var reports: {
                    [report: string]: IEmbedConfiguration
                } = {};
                for (var config of value) {
                    reports[config.name] = {
                        type: "report",
                        tokenType: models.TokenType.Aad,
                        accessToken: accessToken,
                        embedUrl: config.embedUrl,
                        id: config.id,
                        settings: settings
                    }
                }
                this.setState({ reports: reports })
            }
        ))
    }

    componentDidMount(): void {
        if (reportRef !== null) {
            reportContainer = reportRef.current;
        }
        this.authenticate();
        if (accessToken) this.getReports();
    }

    componentWillUnmount(): void {
        PowerBIService.reset(reportContainer);
    }

    // Authenticating to get the access token
    authenticate(): void {
        const thisObj = this;

        const msalConfig = { auth: { clientId: clientId } };

        const loginRequest = { scopes: scopes };

        const msalInstance: UserAgentApplication = new UserAgentApplication(msalConfig);

        function successCallback(response: AuthResponse): void {
            if (response.tokenType === "id_token") {
                thisObj.authenticate();
            } else if (response.tokenType === "access_token") {
                accessToken = response.accessToken;
                console.log('successCallback', response.account.userName);
                thisObj.tryRefreshUserPermissions();
            } else {
                thisObj.setState({ error: [("Token type is: " + response.tokenType)] });
            }
        }

        function failCallBack(error: AuthError): void {
            thisObj.setState({ error: ["Redirect error: " + error] });
        }

        msalInstance.handleRedirectCallback(successCallback, failCallBack);

        // check if there is a cached user
        if (msalInstance.getAccount()) {

            // get access token silently from cached id-token
            msalInstance.acquireTokenSilent(loginRequest)
                .then((response: AuthResponse) => {

                    // get access token from response: response.accessToken
                    accessToken = response.accessToken;
                    console.log('acquireTokenSilent', response.account.userName);
                })
                .catch((err: AuthError) => {

                    // refresh access token silently from cached id-token
                    // makes the call to handleredirectcallback
                    if (err.name === "InteractionRequiredAuthError") {
                        msalInstance.acquireTokenRedirect(loginRequest);
                    }
                    else {
                        thisObj.setState({ error: [err.toString()] })
                    }
                });
        } else {
            // user is not logged in or cached, you will need to log them in to acquire a token
            msalInstance.loginRedirect(loginRequest);
        }
    }

    // Power BI REST API call to refresh User Permissions in Power BI
    // Refreshes user permissions and makes sure the user permissions are fully updated
    // https://docs.microsoft.com/rest/api/power-bi/users/refreshuserpermissions
    tryRefreshUserPermissions(): void {
        fetch("https://api.powerbi.com/v1.0/myorg/RefreshUserPermissions", {
            headers: {
                "Authorization": "Bearer " + accessToken
            },
            method: "POST"
        })
            .then(function (response) {
                if (response.ok) {
                    console.log("User permissions refreshed successfully.");
                } else {
                    // Too many requests in one hour will cause the API to fail
                    if (response.status === 429) {
                        console.error("Permissions refresh will be available in up to an hour.");
                    } else {
                        console.error(response);
                    }
                }
            })
            .catch(function (error) {
                console.error("Failure in making API call." + error);
            });
    }


    render(): JSX.Element {

        const thisObj = this;

        if (this.state.error.length) {

            // Cleaning the report container contents and rendering the error message in multiple lines
            reportContainer.textContent = "";
            this.state.error.forEach(line => {
                reportContainer.appendChild(document.createTextNode(line));
                reportContainer.appendChild(document.createElement("br"));
            });
        }
        else if (this.state.report) {

            report.off("loaded");
            report.on("loaded", function () {
                console.log("Report load successful");
                if (thisObj.props.onLoad) {
                    thisObj.props.onLoad();
                }
            });

            report.off("error");
            report.on("error", function (event) {
                const errorMsg = event.detail;
                console.error(errorMsg);
            });
        }
        return <div id="reportBox">
            <div id="reportHeader">
                <div className="d-flex justify-content-end ">
                    <label className="me-2" htmlFor="xktFiles">Select report:</label>
                    <select className="form-select form-select-sm w-auto" id="report"
                        onChange={event => this.embedReport(this.state.reports[event.target.value])}
                    >
                        <option key="default"></option>
                        {Object.keys(this.state.reports).map(report => <option key={report} value={report}>{report}</option>)}
                    </select>
                </div>
            </div>
            <div className="mt-2" id="reportContainer" ref={reportRef} >
            </div>
        </div>
    }
}

export default PowerBI;
export { models, Report };