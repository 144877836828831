import React, { RefObject, useEffect, useState } from 'react';
import { Storage } from '@aws-amplify/storage';
import Viewer, {CustomViewer} from "./Viewer";

let viewerRef: RefObject<any>;

interface Props { dataset: string };
interface State {  };

class ViewerTab extends React.Component<Props, State> {

  constructor(props:Props) {
    super(props);
    viewerRef = React.createRef();
  }

  loadSingleModel= (blob: Blob, modelId: string) => {
    const viewer = viewerRef.current.state.viewer as CustomViewer;
    viewer.scene.modelIds.map(modelId => viewer.scene.models[modelId].destroy())
    viewer.loadModel(blob, modelId);
  }

  CloudFilePicker = () => {

    const [cloudFiles, setCloudFiles]: [JSX.Element[], any] = useState([]);

    useEffect(() => {
      const fetchFiles = async () => {
        const response = await Storage.list(`${this.props.dataset}/models/xkt/`)
          .then((result) => result.filter(({ size }: { size: any }) => size > 0).map(
            ({ key }: { key: any }) =>
              <option key={key} value={key}>
                {key.split('/').at(-1)}
              </option>
          ))
          .catch(err => console.log(err))
        if (response) setCloudFiles(response);
      };

      if (this.props.dataset && cloudFiles.length === 0) fetchFiles();
    }, [cloudFiles]);

    const onChange = (event: any) => {
      const modelId= event.target.value;
      Storage.get(modelId, {
        download: true,
        progressCallback(progress) { console.log(`Downloaded: ${progress.loaded}/${progress.total}`) }
      }).then(
        result => this.loadSingleModel(result.Body as Blob, modelId)
      )
    }

    return <div className="d-flex">
      <label className="mt-1 me-2" htmlFor="xktFiles">Cloud storage:</label>
      <select className="form-select form-select-sm w-auto" id="xktFiles" defaultValue={""} onChange={onChange}>
        <option key="default"></option>
        {cloudFiles}
      </select>
    </div>
  }

  LocalFilePicker = () => {
    return <div>
      <input type="file" onChange={event => {
        if (event.target.files) {
          console.log(event.target.files[0]);
          this.loadSingleModel(event.target.files[0], '')
        }
      }} />
    </div>
  }

  render() {
    return <div>
      <div className="d-flex justify-content-between">
        <this.LocalFilePicker />
        <this.CloudFilePicker />
      </div>
      <div className="mt-3">
        <Viewer ref={viewerRef} />
      </div>
    </div>
  }
}

export default ViewerTab;