/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:50c90691-3e0d-4c7d-9286-d6d9bfee26b9",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_hMynpUdlP",
    "aws_user_pools_web_client_id": "5slcbrfm2k9ocigm5can5baep0",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "ifcdbapi",
            "endpoint": "https://sjy0aj7lqc.execute-api.eu-central-1.amazonaws.com/staging",
            "region": "eu-central-1"
        }
    ],
    "aws_content_delivery_bucket": "ifcdbviewer-20220531161558-hostingbucket-staging",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://d3h35kj5e4xlix.cloudfront.net",
    "aws_user_files_s3_bucket": "bau40-ifcdb-viewer-sheets115847-staging",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
