import React from 'react'
import { Viewer as XKTViewer, XKTLoaderPlugin, Entity, MetaObject } from '@xeokit/xeokit-sdk';
import { Storage } from '@aws-amplify/storage';
import "./Viewer.css";

class CustomViewer extends XKTViewer {

  objectIds: string[] | undefined;
  displayOption: string = 'Filter';

  setModels = async (dataset: string, modelIds: string[]) => {
    const activeModelIds = new Set(this.scene.modelIds)
    const filterModelIds = new Set(modelIds)

    const removeModelIds = this.scene.modelIds.filter(v => !filterModelIds.delete(v));
    removeModelIds.map(modelId => this.scene.models[modelId].destroy())

    const addModelIds = modelIds.filter(v => !activeModelIds.delete(v));
    addModelIds.map(modelId => this.loadModelFromCloud(dataset, modelId))
  }

  setObjects = async (objectIds: string[]) => {
    this.objectIds = objectIds;
    this.refresh();
  }

  refresh = () => {
    if (this.objectIds === undefined) return;

    console.log('refresh', this.objectIds.length, this.scene.objectIds.length);
    this.selectObjects([]);
    this.scene.setObjectsXRayed(this.scene.objectIds, false);

    if (this.displayOption === 'Filter') {
      this.scene.setObjectsVisible(this.scene.objectIds, false);
      this.scene.setObjectsVisible(this.objectIds, true);
    } else {
      this.scene.setObjectsVisible(this.scene.objectIds, true);
      if (this.scene.objectIds.length > this.objectIds.length) {
        this.scene.setObjectsXRayed(this.scene.objectIds, true);
        this.selectObjects(this.objectIds);
      }
    }
  }

  loadModelFromCloud = async (dataset: string, fileName: string) => {
    Storage.get(`${dataset}/models/xkt/${fileName.replace(".ifc", ".xkt")}`, {
      download: true,
      progressCallback(progress) { console.log(`Downloaded: ${progress.loaded}/${progress.total}`) }
    }).then(result => this.loadModel(result.Body as Blob, fileName))
  }

  loadModel = async (blob: Blob, modelId: string) => {
    const data = await blob.arrayBuffer();
    const xktLoader = new XKTLoaderPlugin(this);
    const sceneModel = xktLoader.load({
      id: modelId,
      xkt: data,
      edges: true
    });
    sceneModel.on("loaded", () => {
      this.cameraFlight.flyTo(sceneModel);
    });
  };

  enablePicker = (parentComponent: Viewer) => {

    this.cameraControl.on("picked", (pickResult) => {
      if (pickResult.entity) {
        const metaObject = this.metaScene.metaObjects[pickResult.entity.id];
        parentComponent.setState({ pickedObject: metaObject });
        this.selectObjects([pickResult.entity.id as string]);
      }
    });

    this.cameraControl.on("pickedNothing", () => {
      this.selectObjects([]);
    });
  }

  selectObjects = (objectIds: string[]) => {
    this.scene.setObjectsSelected(this.scene.objectIds, false);
    this.scene.setObjectsSelected(objectIds, true);
  }

  enableHover = () => {
    var lastEntity: Entity | null = null;
    const thisObj = this;

    (this.scene as any).input.on("mousemove", function (coords: number[]) {

      var hit = thisObj.scene.pick({
        canvasPos: coords
      });

      if (hit && hit?.entity) {
        if (!lastEntity || hit.entity.id !== lastEntity.id) {
          if (lastEntity) {
            lastEntity.highlighted = false;
          }
          lastEntity = hit.entity;
          hit.entity.highlighted = true;
        }
      } else {
        if (lastEntity) {
          lastEntity.highlighted = false;
          lastEntity = null;
        }
      }
    });
  }
}

interface Props {
  filterOption?: boolean
};
interface State {
  pickedObject?: MetaObject,
  viewer?: CustomViewer,
};

class Viewer extends React.Component<Props, State> {

  componentDidMount(): void {
    const viewer = new CustomViewer({ canvasId: "myCanvas" });
    viewer.enablePicker(this);
    viewer.enableHover();
    this.setState({ viewer: viewer })
  }

  getMetaObject(): JSX.Element | undefined {
    if (this?.state?.pickedObject) {
      const { id, name, type } = this.state.pickedObject;
      return <table>
        <tbody>
          <tr>
            <th>id</th>
            <td>{id}</td>
          </tr>
          <tr>
            <th>name</th>
            <td>{name}</td>
          </tr>
          <tr>
            <th>type</th>
            <td>{type}</td>
          </tr>
        </tbody>
      </table>
    }
  }


  onChangeRadioValue = (event: any) => {
    if (this.state.viewer) {
      this.state.viewer.displayOption = event.target.value;
      this.state.viewer.refresh();
    }
  }

  filterOption(): JSX.Element {
    if (this.props.filterOption) {
      return <div id="spacer" onChange={this.onChangeRadioValue}>
        <input className="form-check-input" type="radio" name="exampleRadios" value="Filter" defaultChecked />
        <label className="form-check-label me-2">
          Filter
        </label>
        <input className="form-check-input" type="radio" name="exampleRadios" value="X-Ray" />
        <label className="form-check-label me-4">
          X-Ray
        </label>
      </div>
    } else {
      return <div></div>
    }
  }

  render(): JSX.Element {
    return <div>
      {this.filterOption()}
      <div className="mt-2">
        <canvas id="myCanvas" />
        <div className="metaObject">
          {this.getMetaObject()}
        </div>
      </div>
    </div>
  }
};

export { CustomViewer };
export default Viewer;