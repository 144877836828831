import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { Storage } from '@aws-amplify/storage';
import { CognitoUserAmplify } from '@aws-amplify/ui';


interface AppState {
    datasets: string[],
    activeDataset: string
};

interface Props {
    signOut: any,
    user: CognitoUserAmplify,
    appState: { datasets: string[], activeDataset: string },
    setAppState: (state: AppState) => void
};

interface State {
    email: string,
    group: string
};

class Navigation extends React.Component<Props, State> {

    session: any;

    constructor(props: Props) {
        super(props);
        this.session = (this.props.user as any).signInUserSession;
        if (this.session) {
            const payload = this.session.idToken.payload;
            this.state = { email: payload.email, group: payload['cognito:groups'][0] }
        } else {
            this.state = { email: '', group: '' }
        }
    }

    componentDidMount(): void {
        const queryDatasets = async () => {
            let datasets: string[];
            if (this.state.group === 'bau40') {
                datasets = await Storage.list('')
                    .then(result => Array.from(
                        new Set(result
                            .filter((item: any) => item.size > 0)
                            .map(item => item.key?.split('/').at(0) as string)
                        ))
                    )
            } else {
                datasets = [this.state.group]
            }
            this.props.setAppState({ datasets: datasets, activeDataset: datasets[0] });
        };
        if (this.session && this.props.appState.datasets.length === 0) queryDatasets();
    }

    render(): ReactNode {
        const className = "me-2 btn btn-sm btn-outline-primary"
        return <header>
            <div className="d-flex justify-content-between align-items-center pb-3 mb-3 border-bottom">
                <div>
                    <NavLink className={className} to="/">
                        Home
                    </NavLink>
                    <NavLink className={className} to="/viewer">
                        Viewer
                    </NavLink>
                    <NavLink className={className} to="/analytics">
                        Analytics
                    </NavLink>
                    {/* <NavLink className={className} to="/excel">
                        Excel
                    </NavLink>
                    */}
                </div>
                <div className="d-flex">
                    <div className="mt-1 me-2">
                        Logged in as {this.state.email},
                    </div>
                    <label className="mt-1 me-2" htmlFor="client">Account</label>
                    <select id="client" className="me-2 form-select form-select-sm w-auto"
                        defaultValue={this.props.appState.activeDataset}
                        onChange={event => this.props.setAppState({ datasets: this.props.appState.datasets, activeDataset: event.target.value })}
                    >
                        {this.props.appState.datasets.map(value => <option key={value} value={value}>{value}</option>)}
                    </select>
                    <button className="btn btn-sm btn-outline-primary me-2" onClick={this.props.signOut}>
                        Sign out
                    </button>
                </div>
            </div>
        </header >
    }

}

export default Navigation;